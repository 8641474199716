<template>
  <div class="header mb-5">
    <v-app-bar color="primary" flat height="75">
      <v-container class="py-0 fill-height">
        <img width="120" class="logo" src="@/assets/logo.png" />
        <v-spacer></v-spacer>
        <v-btn v-if="!$store.getters.loggedin" small to="/" text color="white">
          تسجيل دخول
          <v-icon class="ms-2">person</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .logo {
    filter: invert(1);
  }
}
</style>
<script>
export default {
  name: "GuestHeader",
  data: () => ({}),
};
</script>
